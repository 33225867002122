define("fd-admin/templates/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lf75aMW+",
    "block": "[[[10,0],[14,1,\"user-details\"],[12],[1,\"\\n   \"],[10,0],[14,1,\"user-details-panel\"],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@user\"],[[30,0,[\"model\",\"user\"]]]],[[\"default\"],[[[],[]]]]],[1,\"\\n      \"],[10,0],[12],[1,\"\\n         \"],[8,[39,1],null,[[\"@sites\"],[[30,0,[\"model\",\"sites\"]]]],[[\"default\"],[[[],[]]]]],[1,\"\\n         \"],[8,[39,2],null,[[\"@providers\"],[[30,0,[\"model\",\"providers\"]]]],[[\"default\"],[[[],[]]]]],[1,\"\\n         \"],[11,\"button\"],[24,0,\"fd-button primary show-logs\"],[24,4,\"button\"],[4,[38,3],[\"click\",[28,[37,4],[[30,0,[\"showLogs\"]],[30,0,[\"model\",\"user\",\"id\"]]],null]],null],[12],[1,\"Search Logs\"],[13],[1,\"\\n      \"],[13],[1,\"\\n   \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\\n\"]],[],false,[\"user-info-panel\",\"sites-panel\",\"login-providers-panel\",\"on\",\"fn\"]]",
    "moduleName": "fd-admin/templates/user.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});