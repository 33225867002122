define("fd-admin/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pXu8RpJ2",
    "block": "[[[10,0],[14,1,\"index-view\"],[12],[1,\"\\n\"],[10,0],[14,0,\"dashboard-nav\"],[12],[1,\"\\n\\n    \\n       \"],[8,[39,0],[[24,0,\"dashboard-nav-item\"]],[[\"@route\"],[\"partners\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,1],[[24,0,\"fa-icon\"]],[[\"@icon\"],[\"handshake\"]],null],[1,\"\\n            PARTNERS\\n       \"]],[]]]]],[1,\"  \\n  \\n        \"],[8,[39,0],[[24,0,\"dashboard-nav-item\"]],[[\"@route\"],[\"users\"]],[[\"default\"],[[[[1,\"\\n          \"],[8,[39,1],[[24,0,\"fa-icon\"]],[[\"@icon\"],[\"users\"]],null],[1,\"\\n            USERS\\n       \"]],[]]]]],[1,\" \\n\\n       \"],[8,[39,0],[[24,0,\"dashboard-nav-item\"]],[[\"@route\"],[\"logs\"]],[[\"default\"],[[[[1,\"\\n         \"],[8,[39,1],[[24,0,\"fa-icon\"]],[[\"@icon\"],[\"heartbeat\"]],null],[1,\"\\n            Logs\\n       \"]],[]]]]],[1,\" \\n\\n\\n\"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\\n\"]],[],false,[\"link-to\",\"fa-icon\"]]",
    "moduleName": "fd-admin/templates/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});