define("fd-admin/templates/partners", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mxmWh7FP",
    "block": "[[[10,0],[14,1,\"partners-view\"],[12],[1,\"\\n    \\n\"],[41,[30,0,[\"processing\"]],[[[1,\"        \"],[8,[39,1],null,[[\"@syncSummary\"],[[30,0,[\"syncSummary\"]]]],[[\"default\"],[[[],[]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,2],null,[[\"@nukeEm\"],[[30,0,[\"updateSizes\"]]]],[[\"default\"],[[[],[]]]]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[],false,[\"if\",\"lab-update-summary\",\"nuke-button\"]]",
    "moduleName": "fd-admin/templates/partners.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});