define("fd-admin/services/api-host", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    host: Ember.computed(function () {
      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      return config.SERVER_HOST;
    }),
    albumworksUrl: Ember.computed(function () {
      var _config$ALBUMWORKS_UR;

      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      return (_config$ALBUMWORKS_UR = config.ALBUMWORKS_URL) !== null && _config$ALBUMWORKS_UR !== void 0 ? _config$ALBUMWORKS_UR : config.SERVER_HOST;
    }),
    uploadHost: Ember.computed(function () {
      var _config$UPLOAD_HOST;

      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      return (_config$UPLOAD_HOST = config.UPLOAD_HOST) !== null && _config$UPLOAD_HOST !== void 0 ? _config$UPLOAD_HOST : config.SERVER_HOST;
    }),
    getHeaders: function getHeaders() {
      var headers = {};
      headers['content-type'] = "application/json";
      headers['accepts'] = "application/json";

      if (this.get('session.isAuthenticated')) {
        var token = this.get('session.data.authenticated.access_token');

        if (token) {
          headers['Authorization'] = "Bearer ".concat(token);
        }
      }

      return headers;
    },
    postRequest: function postRequest(url, data, headers) {
      var defaultHeaders = this.getHeaders();

      if (headers) {
        for (var key in headers) {
          defaultHeaders[key] = headers[key];
        }
      }

      var request = _jquery.default.ajax({
        url: url,
        type: 'POST',
        contentType: 'application/json',
        headers: defaultHeaders,
        data: data
      });

      return request;
    },
    getRequest: function getRequest(url, headers) {
      var defaultHeaders = this.getHeaders();

      if (headers) {
        for (var key in headers) {
          defaultHeaders[key] = headers[key];
        }
      }

      var request = _jquery.default.ajax({
        url: url,
        type: 'GET',
        contentType: 'application/json',
        headers: defaultHeaders
      });

      return request;
    },
    deleteRequest: function deleteRequest(url, headers) {
      var defaultHeaders = this.getHeaders();

      if (headers) {
        for (var key in headers) {
          defaultHeaders[key] = headers[key];
        }
      }

      var request = _jquery.default.ajax({
        url: url,
        type: 'DELETE',
        contentType: 'application/json',
        headers: defaultHeaders
      });

      return request;
    }
  });

  _exports.default = _default;
});