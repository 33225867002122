define("fd-admin/authenticators/fs-auth", ["exports", "ember-simple-auth/authenticators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    store: Ember.inject.service('store'),
    apiHost: Ember.inject.service(),
    session: Ember.inject.service(),
    authenticate: function authenticate(userId, password) {
      var self = this;
      var apiHost = this.apiHost;
      var url = "".concat(apiHost.get('host'), "/api/auth");
      var data = {
        provider: "fundy",
        loginName: userId,
        password: password
      };
      data = JSON.stringify(data);
      return apiHost.postRequest(url, data).then(function (response) {
        return {
          user: response.user,
          access_token: response.token
        };
      }).catch(function (err) {
        self.get('session').invalidate();
        return Promise.reject(err.responseJSON);
      });
    },
    restore: function restore(data) {
      if (data.user && data.access_token) {
        // Todo actually refesh token
        return Promise.resolve(data);
      }

      return Promise.reject();
    }
  });

  _exports.default = _default;
});