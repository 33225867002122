define("fd-admin/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LDbfRwNc",
    "block": "[[[10,0],[14,0,\"login-page\"],[12],[1,\"\\n    \"],[8,[39,0],null,null,[[\"default\"],[[[],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"login-form\"]]",
    "moduleName": "fd-admin/templates/login.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});