define("fd-admin/templates/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XU7HYSjN",
    "block": "[[[10,0],[14,0,\"panel-group\"],[12],[1,\"\\n\"],[10,0],[14,0,\"panel panel-default\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"panel-body table-container\"],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@model\"],[[30,0,[\"model\"]]]],[[\"default\"],[[[],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[13]],[],false,[\"users-table\"]]",
    "moduleName": "fd-admin/templates/users.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});