define("fd-admin/components/table-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{!-- BEGIN-SNIPPET table-loader --}}
  <div class="spinner">
    <div class="rect1"></div>
    <div class="rect2"></div>
    <div class="rect3"></div>
    <div class="rect4"></div>
    <div class="rect5"></div>
  </div>
  {{!-- END-SNIPPET --}}
  */
  {
    "id": "qcCuIjZm",
    "block": "[[[10,0],[14,0,\"spinner\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"rect1\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"rect2\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"rect3\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"rect4\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"rect5\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "fd-admin/components/table-loader.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});