define("fd-admin/components/no-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{!-- BEGIN-SNIPPET no-data --}}
  <div class="row">
    <div class="col-md-2 col-md-offset-4">
      <img src="images/tomster.png" alt="">
    </div>
    <div class="col-md-4">
      <h2>Hi there!</h2>
      <p>Uhh... Looks like you've delete all our users</p>
      <p>Lets see, have you tried turning it off and on?</p>
    </div>
  </div>
  {{!-- END-SNIPPET --}}
  
  */
  {
    "id": "4qwbqpoQ",
    "block": "[[[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col-md-2 col-md-offset-4\"],[12],[1,\"\\n    \"],[10,\"img\"],[14,\"src\",\"images/tomster.png\"],[14,\"alt\",\"\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"col-md-4\"],[12],[1,\"\\n    \"],[10,\"h2\"],[12],[1,\"Hi there!\"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"Uhh... Looks like you've delete all our users\"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"Lets see, have you tried turning it off and on?\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "fd-admin/components/no-data.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});